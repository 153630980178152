import React from "react";
import { Grid, Paper, Typography, Button, Stack } from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CallIcon from "@mui/icons-material/Call";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const ActionPanel = () => {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        borderRadius: 2,
        background: "linear-gradient(135deg, #ffffff 30%, #f5f5f5 100%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "93%",
      }}
    >
      <Typography
        variant="h6"
        sx={{ mb: 2, color: "#333", fontWeight: "bold" }}
      >
        Actions
      </Typography>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Link
          to="/dashboard/all-collections"
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#007bff", // Modern blue
              color: "#fff",
              height: "50px",
              borderRadius: "8px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#0056b3", // Darker blue on hover
              },
              justifyContent: "flex-start",
              width: "100%",
            }}
            startIcon={<ManageSearchIcon />}
          >
            Manage Collections
          </Button>
        </Link>
        <Link to="/dashboard/new-collection" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#28a745", // Modern green
              color: "#fff",
              height: "50px",
              borderRadius: "8px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#218838", // Darker green on hover
              },
              justifyContent: "flex-start",
              width: "100%",
            }}
            startIcon={<AddCircleIcon />}
          >
            Add New Collection
          </Button>
        </Link>
        <Link to="/dashboard/new-call-log" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#17a2b8", // Modern teal
              color: "#fff",
              height: "50px",
              borderRadius: "8px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#117a8b", // Darker teal on hover
              },
              justifyContent: "flex-start",
              width: "100%",
            }}
            startIcon={<CallIcon />}
          >
            Add Call Log
          </Button>
        </Link>
        <Link to="/dashboard/settings" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#6c757d", // Modern grey
              color: "#fff",
              height: "50px",
              borderRadius: "8px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#5a6268", // Darker grey on hover
              },
              justifyContent: "flex-start",
              width: "100%",
            }}
            startIcon={<SettingsIcon />}
          >
            Settings
          </Button>
        </Link>
      </Stack>
    </Paper>
  );
};

export default ActionPanel;
