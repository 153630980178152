import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { SubHeading } from "../styled/styledTypography";
import moment from "moment";
import Loading from "./Loading";

export default function CertificateCard({
  title,
  data,
  emailHandler,
  generateHanlder,
  download,
  loading,
}) {
  return (
    <Box
      sx={{
        border: "1px solid",
        width: "10rem",
        height: "15rem",
        padding: 5,
        textAlign: "center",
      }}
    >
      <SubHeading>{title}</SubHeading>

      {loading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {data?.generated ? (
            <Box
              sx={{ mt: 1, display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Typography>
                <Typography component={"span"} sx={{ fontWeight: "bold" }}>
                  {" "}
                  Generated On: <br />
                </Typography>{" "}
                {moment(data?.date).format("DD / MM / YYYY")} <br />
                {moment(data?.date).format("hh:mm a")}
              </Typography>

              <Button variant={"contained"} onClick={() => download()}>
                Download
              </Button>
              <Button variant={"contained"} onClick={() => emailHandler(title)}>
                Email To Client
              </Button>
              <Button
                variant={"contained"}
                onClick={() => generateHanlder(title)}
              >
                Generate New
              </Button>
            </Box>
          ) : (
            <Button
              onClick={() => generateHanlder()}
              sx={{ m: "25px 0" }}
              color={"primary"}
              variant="contained"
            >
              Generate Now
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}
