import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import TopBar from "../../common/TopBar";
import KPIBar from "./KPIBar";
import BarChartComponent from "../../BarChart";
import { Line, Pie } from "react-chartjs-2";
import Loading from "../../common/Loading";
import api from "../../../misc/api";
import moment from "moment/moment";
import {
  AirportShuttle,
  AssignmentLate,
  AttachMoney,
  Devices,
  MoneyOff,
  Receipt,
  ShoppingCart,
} from "@mui/icons-material";
import ActionPanel from "./ActionPanel";
const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      labels: {
        color: "#333",
        font: {
          size: 16,
          family: "Roboto, sans-serif",
        },
        margin: 10,
        usePointStyle: true,
      },
    },
    tooltip: {
      enabled: true,
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      titleColor: "#fff",
      bodyColor: "#fff",
      cornerRadius: 10,
      titleFont: { size: 16, weight: "bold" },
      bodyFont: { size: 14 },
      padding: 12,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: "#666",
        font: {
          size: 14,
          family: "Roboto, sans-serif",
        },
      },
    },
    y: {
      grid: {
        color: "rgba(200, 200, 200, 0.2)",
      },
      ticks: {
        color: "#666",
        font: {
          size: 14,
          family: "Roboto, sans-serif",
        },
      },
    },
  },
};

const modernPieChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      labels: {
        color: "#333",
        font: {
          size: 14,
          weight: "bold",
        },
      },
    },
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      titleColor: "#fff",
      bodyColor: "#fff",
      borderColor: "#fff",
      borderWidth: 1,
      callbacks: {
        label: (tooltipItem) => {
          const label = tooltipItem.label || "";
          const value = tooltipItem.raw;
          return `${label}: ${value}`;
        },
      },
    },
  },
  animations: {
    tension: {
      duration: 1000,
      easing: "easeOutBounce",
      from: 1,
      to: 0,
    },
  },
};

export default function Content() {
  const [updatedTime, setUpdatedTime] = useState(null);
  const [invoiceChartData, setInvoiceChartData] = useState({});
  const [collectionsChartData, setCollectionsChartData] = useState({});
  const [totalCollections, setTotalCollections] = useState(0);
  const [totalOverdue, setTotalOverdue] = useState(0);
  const [pendingEnquiries, setPendingEnquiries] = useState(0);
  const [summAllItemsCollected, setSumAllItemsCollected] = useState(0);
  const [pieData, setPieData] = useState({});
  const [totalInvoiceGenerated, setTotalInvoiceGenerated] = useState(0);
  const [totalInvoicePaid, setTotalInvoicePaid] = useState(0);
  const [totalItemsCollected, setTotalItemsCollected] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState("");
  const [kpiData, setKpiData] = useState([]);
  const [kpiData2, setKpiData2] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const _end = moment().toDate();
    const _start = moment().subtract(28, "days").toDate();

    setStartDate(_start);
    setEndDate(_end);
  }, []);

  const handleRefersh = () => fetchData();

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.post(`analytics/getData`, {
        startDate,
        endDate,
      });
      const { data } = response;

      const _time = moment(Date.now()).format("DD-MM-YYYY hh:mm A");

      setUpdatedTime(_time);
      setInvoiceChartData({
        labels: data.labels,
        datasets: [
          {
            label: "Total Fee",
            data: data.fees,
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 400);
              gradient.addColorStop(0, "rgba(156,39,176,0.8)");
              gradient.addColorStop(1, "rgba(156,39,176,0.2)");
              return gradient;
            },
            borderColor: "rgba(156,39,176,1)",
            borderWidth: 3,
            pointBackgroundColor: "rgba(156,39,176,1)",
            pointBorderColor: "#fff",
            pointHoverRadius: 8,
            pointHoverBorderWidth: 3,
            tension: 0.5, // Smoother curves
          },
          {
            label: "Total Invoice Generated",
            data: data.invoiceGenerated,
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 400);
              gradient.addColorStop(0, "rgba(255,193,7,0.8)");
              gradient.addColorStop(1, "rgba(255,193,7,0.2)");
              return gradient;
            },
            borderColor: "rgba(255,193,7,1)",
            borderWidth: 3,
            pointBackgroundColor: "rgba(255,193,7,1)",
            pointBorderColor: "#fff",
            pointHoverRadius: 8,
            pointHoverBorderWidth: 3,
            tension: 0.5,
          },
          {
            label: "Total Invoice Paid",
            data: data.invoicePaid,
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 400);
              gradient.addColorStop(0, "rgba(33,150,243,0.8)");
              gradient.addColorStop(1, "rgba(33,150,243,0.2)");
              return gradient;
            },
            borderColor: "rgba(33,150,243,1)",
            borderWidth: 3,
            pointBackgroundColor: "rgba(33,150,243,1)",
            pointBorderColor: "#fff",
            pointHoverRadius: 8,
            pointHoverBorderWidth: 3,
            tension: 0.5,
          },
        ],
      });

      setCollectionsChartData({
        labels: data.labels,
        datasets: [
          {
            label: "Collections",
            data: data.counts,
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 400);
              gradient.addColorStop(0, "rgba(75,192,192,0.7)");
              gradient.addColorStop(1, "rgba(75,192,192,0.2)");
              return gradient;
            },
            borderColor: "#70e000",
            borderWidth: 3,
            pointBackgroundColor: "#70e000",
            pointBorderColor: "#fff",
            pointHoverRadius: 8,
            pointHoverBorderWidth: 3,
            tension: 0.5, // Smoother curves for a modern look
          },
        ],
      });

      setPieData({
        labels: data.pieChartData.labels,
        datasets: [
          {
            data: data.pieChartData.values,
            backgroundColor: [
              "#FF6384", // Pink
              "#36A2EB", // Blue
              "#FFCE56", // Yellow
              "#FF9F40", // Orange
              "#4BC0C0", // Teal
              "#9966FF", // Purple
              "#FF6F61", // Coral
              "#4CAF50", // Green
              "#9C27B0", // Deep Purple
              "#FF9800", // Deep Orange
              "#00BCD4", // Light Blue
            ],
            borderColor: "#fff",
            borderWidth: 2,
            hoverOffset: 8,
          },
        ],
      });

      // KPIs
      setTotalInvoiceGenerated(
        data.invoiceGenerated.reduce((a, b) => a + b, 0)
      );
      setTotalInvoicePaid(data.invoicePaid.reduce((a, b) => a + b, 0));
      setTotalItemsCollected(
        data.totalItemsCollected.reduce((a, b) => a + b, 0)
      );
      setTotalCollections(data.totalCollection);
      setSumAllItemsCollected(data.sumAllItemsCollected);
      setTotalOverdue(data.invoiceOverdue.reduce((a, b) => a + b, 0));
      setPendingEnquiries(data.pendingEnquiries.reduce((a, b) => a + b, 0));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching the data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [endDate, startDate]);

  useEffect(() => {
    setKpiData([
      {
        id: 1,
        title: "Total Collections",
        value: totalCollections,
        icon: <AirportShuttle />,
      },
      {
        id: 2,
        title: "Total Items",
        value: totalItemsCollected,
        icon: <Devices />,
      },
      {
        id: 2,
        title: "Pending Enquiries",
        value: pendingEnquiries,
        icon: <Receipt />,
      },
    ]);
  }, [totalCollections, totalItemsCollected, pendingEnquiries]);

  useEffect(() => {
    setKpiData2([
      {
        id: 1,
        title: "Invoice Generated",
        value: totalInvoiceGenerated,
        currency: true,
        icon: <AttachMoney />,
        color: "orange",
      },
      {
        id: 2,
        title: "Invoice Paid",
        value: totalInvoicePaid,
        currency: true,
        icon: <MoneyOff />,
        color: "green",
      },
      {
        id: 3,
        title: "Invoice Overdue",
        value: totalOverdue,
        currency: true,
        icon: <AssignmentLate />,
        color: "red",
      },
    ]);
  }, [totalInvoiceGenerated, totalInvoicePaid, totalOverdue]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "linear-gradient(135deg, #f3e7e9 10%, #e3eeff 100%)",
        padding: 3,
      }}
    >
      <Box
        className="bg-gradiant"
        sx={{
          color: "#fff",
          padding: 3,
          borderRadius: 2,
          mb: 4,
        }}
      >
        <TopBar
          showGreeting={true}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          loading={loading}
          handleRefresh={handleRefersh}
        />
      </Box>

      {loading ? (
        <Loading />
      ) : (
        <Box sx={{ p: 4 }}>
          <Grid
            container
            spacing={4}
            alignItems="stretch"
            justifyContent="center"
          >
            {/* KPI Bar */}
            <Grid item xs={12}>
              <Paper
                elevation={3}
                className="bg-gradiant"
                sx={{
                  p: 3,
                  borderRadius: 2,

                  color: "#fff",
                }}
              >
                <KPIBar data={kpiData} data2={kpiData2} />
              </Paper>
            </Grid>

            {/* Chart and Stats Section */}
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "primary.main", fontWeight: 600 }}
                  >
                    Fees and Invoice Overview
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Updated: {updatedTime}
                  </Typography>
                </Stack>
                <Divider sx={{ mb: 2 }} />
                <Line data={invoiceChartData} options={chartOptions} />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "primary.main", fontWeight: 600 }}
                  >
                    Collections
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Updated: {updatedTime}
                  </Typography>
                </Stack>
                <Divider sx={{ mb: 2 }} />
                <Line data={collectionsChartData} options={chartOptions} />
              </Paper>
            </Grid>

            {/* Pie Chart Section */}
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                <Typography
                  variant="h6"
                  sx={{ color: "primary.main", fontWeight: 600, mb: 2 }}
                >
                  Distribution Overview
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Pie data={pieData} />
              </Paper>
            </Grid>
            <Grid item md={6}>
              <ActionPanel />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
