import React from "react";
import Dashboard from "../components/Dashboard";

export default function DashboardView() {
  return (
    <div>
      <Dashboard />
    </div>
  );
}
