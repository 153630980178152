import Overview from "./dashboard/Overview/index";
import AddTransaction from "./dashboard/Transactions/AddTransaction";
import AllTransactions from "./dashboard/Transactions/AllTransactions";
import Manage from "./dashboard/Collections/Manage/Manage";
import AddCollection from "./dashboard/Collections/AddCollection";
import Settings from "./dashboard/Settings";
import AddEditUser from "./dashboard/Settings/Users/AddEditUser";
import CollectionDetail from "./dashboard/Collections/Detail";
import Destruction from "./dashboard/Certificates/Destruction";
import CallLogComponent from "./dashboard/CallLog/Index";
import AddCallLog from "./dashboard/CallLog/AddCallLog";

///
import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  List as ListIcon,
  Home,
  LocalAtm,
  Add,
  AirportShuttle,
  Settings as SettingsIcon,
  PermPhoneMsg,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Accordion,
  AccordionDetails,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LOGO_URL } from "../misc/constant";

const drawerWidth = 260;
const collapsedDrawerWidth = 70;

function ResponsiveDrawer(props) {
  const { window } = props;
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [transactionsMenu, setTransactionsMenu] = React.useState(false);
  const [callLogMenu, setCallLogMenu] = React.useState(false);
  const [collectionsMenu, setCollectionsMenu] = React.useState(false);
  const urlPrefix = "/dashboard";
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleExpandToggle = () => {
    setExpanded(!expanded);
  };

  const handleNavigate = (url) => navigate(url);
  const handleSettings = () => navigate(urlPrefix + "/settings");

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const DrawerContent = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#021526",
          color: "#fff",
          height: "100%",
          width: expanded ? drawerWidth : collapsedDrawerWidth,
          transition: "all 1s ease-in-out",
          position: "relative",
        }}
      >
        {expanded && (
          <Box>
            <Box
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
              }}
            >
              <img src={LOGO_URL} alt="Main-Logo" width={"80%"} />
            </Box>
            <IconButton
              onClick={handleExpandToggle}
              sx={{
                backgroundColor: "#031b31",
                color: "#fff",
                margin: "auto",
                display: "block",
                transition: "top 0.3s",
                mt: 1,
                width: "100%",
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        )}

        <Divider sx={{ borderColor: "#033a52" }} />
        <List mt={4}>
          {!expanded && (
            <Tooltip title="Expand Sidebar" placement="right">
              <ListItem disablePadding>
                <ListItemButton
                  onClick={handleExpandToggle}
                  sx={{ "&:hover": { backgroundColor: "#023e58" } }}
                >
                  <ListItemIcon sx={{ color: "#fff" }}>
                    <MenuIcon />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Tooltip>
          )}
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            <Tooltip title={"Overview"} placement="right">
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ "&:hover": { backgroundColor: "#023e58" } }}
                >
                  <ListItemIcon sx={{ color: "#fff" }}>
                    <Home />
                  </ListItemIcon>
                  {expanded && (
                    <ListItemText primary="Overview" sx={{ color: "#fff" }} />
                  )}
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </Link>
        </List>

        <List>
          <Accordion
            expanded={transactionsMenu}
            sx={{
              backgroundColor: "#021526",
              border: "none",
              boxShadow: "none",
              padding: 0,
            }}
          >
            <Tooltip title="Transactions" placement="right">
              <ListItemButton
                onClick={() => setTransactionsMenu((prev) => !prev)}
                sx={{ "&:hover": { backgroundColor: "#023e58" } }}
              >
                <ListItemIcon sx={{ color: "#fff" }}>
                  <LocalAtm />
                </ListItemIcon>
                {expanded && (
                  <ListItemText primary="Transactions" sx={{ color: "#fff" }} />
                )}
                {transactionsMenu ? (
                  <ExpandLess sx={{ color: "#fff" }} />
                ) : (
                  <ExpandMore sx={{ color: "#fff" }} />
                )}
              </ListItemButton>
            </Tooltip>
            <AccordionDetails sx={{ backgroundColor: "#021526" }}>
              <List>
                <Link
                  to={`${urlPrefix}/all-transactions`}
                  style={{ textDecoration: "none" }}
                >
                  <Tooltip title={"All Transactions"} placement="right">
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ "&:hover": { backgroundColor: "#033a52" } }}
                      >
                        <ListItemIcon sx={{ color: "#fff" }}>
                          <ListIcon />
                        </ListItemIcon>
                        {expanded && (
                          <ListItemText
                            primary="All Transactions"
                            sx={{ color: "#fff" }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                </Link>
                <Link
                  to={`${urlPrefix}/new-transaction`}
                  style={{ textDecoration: "none" }}
                >
                  <Tooltip title={"Add New Transaction"} placement="right">
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ "&:hover": { backgroundColor: "#033a52" } }}
                      >
                        <ListItemIcon sx={{ color: "#fff" }}>
                          <Add />
                        </ListItemIcon>
                        {expanded && (
                          <ListItemText
                            primary="Add New Transaction"
                            sx={{ color: "#fff" }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                </Link>
              </List>
            </AccordionDetails>
          </Accordion>
        </List>

        <List>
          <Accordion
            expanded={collectionsMenu}
            sx={{
              backgroundColor: "#021526",
              border: "none",
              boxShadow: "none",
              padding: 0,
            }}
          >
            <Tooltip title="Collections" placement="right">
              <ListItemButton
                onClick={() => setCollectionsMenu((prev) => !prev)}
                sx={{ "&:hover": { backgroundColor: "#023e58" } }}
              >
                <ListItemIcon sx={{ color: "#fff" }}>
                  <AirportShuttle />
                </ListItemIcon>
                {expanded && (
                  <ListItemText primary="Collections" sx={{ color: "#fff" }} />
                )}
                {collectionsMenu ? (
                  <ExpandLess sx={{ color: "#fff" }} />
                ) : (
                  <ExpandMore sx={{ color: "#fff" }} />
                )}
              </ListItemButton>
            </Tooltip>
            <AccordionDetails sx={{ backgroundColor: "#021526" }}>
              <List>
                <Link
                  to={`${urlPrefix}/all-collections`}
                  style={{ textDecoration: "none" }}
                >
                  <Tooltip title={"Manage Collections"} placement="right">
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ "&:hover": { backgroundColor: "#033a52" } }}
                      >
                        <ListItemIcon sx={{ color: "#fff" }}>
                          <ListIcon />
                        </ListItemIcon>
                        {expanded && (
                          <ListItemText
                            primary="Manage Collections"
                            sx={{ color: "#fff" }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                </Link>
                <Link
                  to={`${urlPrefix}/new-collection`}
                  style={{ textDecoration: "none" }}
                >
                  <Tooltip title={"Add New Collection"} placement="right">
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ "&:hover": { backgroundColor: "#033a52" } }}
                      >
                        <ListItemIcon sx={{ color: "#fff" }}>
                          <Add />
                        </ListItemIcon>
                        {expanded && (
                          <ListItemText
                            primary="Add New Collection"
                            sx={{ color: "#fff" }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                </Link>
              </List>
            </AccordionDetails>
          </Accordion>
        </List>

        <List>
          <Accordion
            expanded={callLogMenu}
            sx={{
              backgroundColor: "#021526",
              border: "none",
              boxShadow: "none",
              padding: 0,
            }}
          >
            <Tooltip title="Call Logs" placement="right">
              <ListItemButton
                onClick={() => setCallLogMenu((prev) => !prev)}
                sx={{ "&:hover": { backgroundColor: "#023e58" } }}
              >
                <ListItemIcon sx={{ color: "#fff" }}>
                  <PermPhoneMsg />
                </ListItemIcon>
                {expanded && (
                  <ListItemText primary="Call Logs" sx={{ color: "#fff" }} />
                )}
                {callLogMenu ? (
                  <ExpandLess sx={{ color: "#fff" }} />
                ) : (
                  <ExpandMore sx={{ color: "#fff" }} />
                )}
              </ListItemButton>
            </Tooltip>
            <AccordionDetails sx={{ backgroundColor: "#021526" }}>
              <List>
                <Link
                  to={`${urlPrefix}/call-logs`}
                  style={{ textDecoration: "none" }}
                >
                  <Tooltip title={"All Call Logs"} placement="right">
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ "&:hover": { backgroundColor: "#033a52" } }}
                      >
                        <ListItemIcon sx={{ color: "#fff" }}>
                          <ListIcon />
                        </ListItemIcon>
                        {expanded && (
                          <ListItemText
                            primary="All Call Logs"
                            sx={{ color: "#fff" }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                </Link>
                <Link
                  to={`${urlPrefix}/new-call-log`}
                  style={{ textDecoration: "none" }}
                >
                  <Tooltip title={"Add New Call Log"} placement="right">
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ "&:hover": { backgroundColor: "#033a52" } }}
                      >
                        <ListItemIcon sx={{ color: "#fff" }}>
                          <Add />
                        </ListItemIcon>
                        {expanded && (
                          <ListItemText
                            primary="Add New Call Log"
                            sx={{ color: "#fff" }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                </Link>
              </List>
            </AccordionDetails>
          </Accordion>
        </List>

        <List>
          <Link to={`${urlPrefix}/settings`} style={{ textDecoration: "none" }}>
            <Tooltip title={"Settings"} placement="right">
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ "&:hover": { backgroundColor: "#023e58" } }}
                >
                  <ListItemIcon sx={{ color: "#fff" }}>
                    <SettingsIcon />
                  </ListItemIcon>
                  {expanded && (
                    <ListItemText primary="Settings" sx={{ color: "#fff" }} />
                  )}
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </Link>
        </List>

        <Box sx={{ marginTop: "auto" }}>
          <List>
            <Tooltip title={"Logout"} placement="right">
              <ListItem disablePadding>
                <ListItemButton
                  onClick={handleLogout}
                  sx={{ "&:hover": { backgroundColor: "#e53e3e" } }}
                >
                  <ListItemIcon sx={{ color: "#fff" }}>
                    <LogoutIcon />
                  </ListItemIcon>
                  {expanded && (
                    <ListItemText primary="Logout" sx={{ color: "#fff" }} />
                  )}
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </List>
        </Box>
      </Box>
    );
  };

  const location = useLocation();
  const currentUrl = location.pathname.split("/")[2]?.toLowerCase() || "";

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="nav"
        sx={{
          width: { sm: expanded ? drawerWidth : collapsedDrawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: expanded ? drawerWidth : collapsedDrawerWidth,
              backgroundColor: "#021526",
            },
          }}
        >
          <DrawerContent />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: expanded ? drawerWidth : collapsedDrawerWidth,
              backgroundColor: "#021526",
            },
          }}
          open
        >
          <DrawerContent />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          minHeight: "100vh",
          flexGrow: 1,
          width: {
            sm: `calc(100% - ${
              expanded ? drawerWidth : collapsedDrawerWidth
            }px)`,
          },
        }}
      >
        <Box item>
          {currentUrl === "" && <Overview />}
          {currentUrl === "settings" && <Settings />}
          {currentUrl === "all-transactions" && <AllTransactions />}
          {currentUrl === "new-transaction" && <AddTransaction />}
          {currentUrl === "new-user" && <AddEditUser />}
          {currentUrl === "edit-user" && <AddEditUser />}
          {currentUrl === "all-collections" && <Manage />}
          {currentUrl === "collections" && <CollectionDetail />}
          {currentUrl === "new-collection" && <AddCollection />}
          {currentUrl === "call-logs" && <CallLogComponent />}
          {currentUrl === "new-call-log" && <AddCallLog />}
          {currentUrl === "generate-destruction" && <Destruction />}
        </Box>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
