import React, { useEffect } from "react";
import AuthCard from "../components/AuthCard";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TITLE_PREFIX } from "../misc/constant";

export default function Auth() {
  const loggedIn = true;
  const navigate = useNavigate();

  useEffect(() => {
    document.title = TITLE_PREFIX + "Login";

    if (loggedIn) {
      navigate("/dashboard", { replace: true });
    }
  }, []);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "linear-gradient(30deg, #2793ad 50%, #8ed130 50%)",
      }}
    >
      <AuthCard />
    </Box>
  );
}
