import { RotateLeft, Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Paper,
  Snackbar,
  TextField,
  Alert,
  Grid,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import React, { useEffect, useState } from "react";
import { Heading } from "../../styled/styledTypography";
import api from "../../../misc/api";
import { TITLE_PREFIX } from "../../../misc/constant";
import { useNavigate } from "react-router-dom";

export default function AddCallLog() {
  const [alert, setAlert] = useState(null);
  const [data, setData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    document.title = TITLE_PREFIX + "Add New Call Log";
  }, []);

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  const handleReset = () => {
    let _data = data;

    Object.keys(_data).forEach((v) => (_data[v] = ""));
    debugger;
    setData(_data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const _data = { ...data };
    const res = await api.post("/call-log/new", _data);
    const status = res.data.success;
    setAlert({
      msg: res.data.msg,
      color: status ? "success" : "error",
      status: true,
    });

    setTimeout(() => {
      navigate("/dashboard/call-logs");
    }, 500);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 10,
      }}
    >
      <Snackbar
        open={alert?.status}
        autoHideDuration={5000}
        onClose={() => setAlert(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={alert?.color}>{alert?.msg}</Alert>
      </Snackbar>
      <Paper
        sx={{
          padding: 5,
          borderRadius: 3,
        }}
        elevation={5}
      >
        <Heading textAlign={"center"} marginBottom={5}>
          Add New Call Log
        </Heading>
        <Box
          component={"form"}
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "550px",
            rowGap: 5,
          }}
        >
          <TextField
            fullWidth
            name="reference"
            id="reference"
            label="Reference"
            onChange={handleChange}
            value={data?.reference}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
            }}
          >
            <TextField
              fullWidth
              name="contactName"
              id="contactName"
              label="Contact Name"
              placeholder="e.g. John Doe"
              onChange={handleChange}
              value={data?.contactName}
            />
            <TextField
              fullWidth
              name="company"
              id="company"
              label="Company"
              placeholder="e.g. BMW"
              onChange={handleChange}
              value={data?.company}
            />
          </Box>
          <TextField
            fullWidth
            name="phoneNo"
            id="phoneNo"
            label="Phone No."
            placeholder="xxxx-xxxxxxx"
            onChange={handleChange}
            value={data?.phoneNo}
          />
          <TextField
            fullWidth
            name="description"
            value={data?.description}
            onChange={handleChange}
            multiline
            minRows={5}
            placeholder="Add some description for the call Log"
          />
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box>
                  <DateTimePicker
                    onChange={(date) => {
                      setData({ ...data, date });
                    }}
                    value={data?.date}
                    format="DD/MM/YYYY hh:mm a"
                    sx={{
                      width: "100%",
                    }}
                    label="Date"
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              disableElevation
              color="success"
              type="submit"
            >
              <Save /> Save
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
