import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Content from "./Content";
import { TITLE_PREFIX } from "../../../misc/constant";

export default function Index() {
  useEffect(() => {
    document.title = TITLE_PREFIX + "Dashboard";
  }, []);

  return (
    <Box>
      <Content />
    </Box>
  );
}
