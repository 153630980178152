import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Alert,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Heading } from "../../../styled/styledTypography";
import api from "../../../../misc/api";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../common/Loading";

export default function AddUser() {
  const [data, setData] = useState(null);
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    const _user = JSON.parse(localStorage.getItem("user")) || null;
    setCurrentUser({ ..._user });
  }, []);

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      setLoading(true);
      api
        .post("/users/id", { id })
        .then((res) => {
          const { data: apiData, success } = res.data;

          if (success) {
            setData({ ...apiData });
            setLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [id]);

  const navigate = useNavigate();

  const handleDelete = () => {
    api.post("/users/delete", { id }).then((res) => {
      const { success } = res.data;
      if (success) {
        navigate("/dashboard/settings");
      }
    });
  };

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();

    const url = id ? "/users/update" : "/users/new";

    api
      .post(url, data)
      .then((res) => {
        const { success, msg } = res.data;

        setAlert({
          status: true,
          msg: msg,
          color: success ? "success" : "error",
        });

        if (success) {
          setTimeout(
            () => navigate("/dashboard/settings", { replace: true }),
            500
          );
        }
      })
      .catch((e) => {
        setAlert({
          status: true,
          msg: e.response.data.msg,
          color: "error",
        });
      });
  };

  return (
    <Box>
      <Snackbar
        open={alert?.status}
        autoHideDuration={5000}
        onClose={() => setAlert(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={alert?.color}>{alert?.msg}</Alert>
      </Snackbar>
      <Box
        sx={{
          width: "50%",
          margin: "auto",
          mt: 10,
        }}
      >
        <Paper
          component={"form"}
          sx={{
            p: 5,
          }}
          onSubmit={handleSubmit}
        >
          {loading ? (
            <Loading />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Heading>{id ? "Edit" : "Add New"} User</Heading>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  name="name"
                  variant="outlined"
                  type="text"
                  fullWidth
                  required
                  value={data?.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  type="email"
                  name="email"
                  variant="outlined"
                  fullWidth
                  required
                  value={data?.email}
                  onChange={handleChange}
                />
              </Grid>
              {!id && (
                <Grid item xs={12}>
                  <TextField
                    label="Password"
                    type="password"
                    name="password"
                    variant="outlined"
                    fullWidth
                    required
                    value={data?.passswrod}
                    onChange={handleChange}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="role-label">Role</InputLabel>
                  <Select
                    labelId="role-label"
                    value={data?.role}
                    name="role"
                    label="Role"
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>Root Admin</MenuItem>
                    <MenuItem value={1}>Office Administrator</MenuItem>
                    <MenuItem value={2}>Recptionist</MenuItem>
                    <MenuItem value={3}>Driver</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  columnGap: 5,
                  justifyContent: id ? "space-between" : "flex-end",
                }}
              >
                {id && (
                  <>
                    <Tooltip title="You can not delete yourself!">
                      <span>
                        <Button
                          variant="contained"
                          color="error"
                          type="button"
                          onClick={handleDelete}
                          disabled={id === currentUser._id}
                        >
                          Delete
                        </Button>
                      </span>
                    </Tooltip>
                  </>
                )}
                <Button variant="contained" color="primary" type="submit">
                  {id ? "Update" : "Save"}
                </Button>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Box>
    </Box>
  );
}
