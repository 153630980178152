import { Box, Typography, styled } from "@mui/material";

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 25,
  fontWeight: "bold",
}));
export const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: "bold",
}));

export const DetailsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: 25,
}));
