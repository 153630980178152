import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  Snackbar,
  Card,
  CardMedia,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import api from "../misc/api";
import { useNavigate } from "react-router-dom";
import { LOGO_URL } from "../misc/constant";
import { AccountCircle, Key } from "@mui/icons-material";
import { CompanyGreen } from "../misc/colours";

export default function AuthCard() {
  const [data, setData] = useState(null);
  const [alert, setAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) =>
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const _data = data;
    api
      .post("/login", _data)
      .then((res) => {
        if (res.data) {
          setAlert({
            status: true,
            msg: "Login Successfully!",
            color: "success",
          });
          setData({});
          localStorage.setItem("user", JSON.stringify(res.data.data));
          localStorage.setItem("token", res.data.accessToken);
          setTimeout(() => {
            navigate("/dashboard", { replace: true });
          }, 1500);
        } else {
          setAlert({
            status: true,
            msg: "Login Failed Try Again!",
            color: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setAlert({
          status: true,
          msg: err.response.data.msg,
          color: "error",
        });
        setIsLoading(false);
      })
      .finally((_) => {});
  };

  return (
    <Box>
      <Snackbar
        open={alert?.status}
        autoHideDuration={5000}
        onClose={() => setAlert(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={alert?.color}>{alert?.msg}</Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          border: "1px solid #000",
          padding: 5,
          bgcolor: "#fff",
        }}
      >
        <Box>
          <img
            src={LOGO_URL}
            alt="Logo"
            style={{
              display: "block",
              maxWidth: "400px",
              margin: "auto",
            }}
          />
          <Typography
            variant="h1"
            sx={{
              fontSize: 24,
              textAlign: "center",
              marginBottom: 4,
              fontWeight: "bold",
              my: 2,
            }}
          >
            Admin Portal
          </Typography>
        </Box>
        <Box>
          <Box
            component={"form"}
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
            }}
          >
            <TextField
              required
              fullWidth
              variant="outlined"
              name="email"
              id="email"
              type="email"
              placeholder="Email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
            />
            <TextField
              required
              fullWidth
              name="password"
              id="password"
              placeholder="Password"
              type="password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Key />
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                disableElevation
                color="success"
                sx={{ width: "100%", py: 1, background: CompanyGreen }}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Sign In
                  </Typography>
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
