module.exports = {
  darkBg: "#618264",
  lightBg: "#79AC78",
  superLightBg: "#B0D9B1",
  shadow: "#D0E7D2",
  primaryColor: "#3EC1D3",
  darkText: "#618264",
  secondaryColor: "#FF165D",
  lightText: "#fff",

  CompanyGreen: "#8ed130",
};
