import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./Views/Home";
import Login from "./components/SideLogin";
import Auth from "./Views/Auth";
import DashboardView from "./Views/DashboardView";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import colors from "./misc/colours";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import "./App.css";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {},
      },
    },
  },
  palette: {
    primary: {
      main: "#021526",
      light: colors.lightBg,
      contrastText: colors.lightText,
    },
  },
  typography: {
    h4: {
      fontSize: 25,
      fontWeight: 600,
      color: colors.darkBg,
    },
  },
});

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/");
    } else {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        localStorage.removeItem("token");
        navigate("/");
      }
    }
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/dashboard" element={<DashboardView />} />
        <Route path="/dashboard/settings" element={<DashboardView />} />
        <Route path="/dashboard/mail" element={<DashboardView />} />
        <Route path="/dashboard/new-transaction" element={<DashboardView />} />
        <Route path="/dashboard/all-transactions" element={<DashboardView />} />
        <Route path="/dashboard/new-client" element={<DashboardView />} />
        <Route path="/dashboard/all-clients" element={<DashboardView />} />
        <Route path="/dashboard/new-user" element={<DashboardView />} />
        <Route path="/dashboard/edit-user/:id" element={<DashboardView />} />
        <Route path="/dashboard/call-logs" element={<DashboardView />} />
        <Route path="/dashboard/new-call-log" element={<DashboardView />} />

        <Route
          path="/dashboard/generate-destruction"
          element={<DashboardView />}
        />

        <Route path="/dashboard/all-collections" element={<DashboardView />} />
        <Route path="/dashboard/collections/:id" element={<DashboardView />} />
        <Route path="/dashboard/new-collection" element={<DashboardView />} />
        <Route path="/dashboard" element={<Home />} />
        <Route path="/sign-in" element={<Login />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
