import React, { useEffect, useState } from "react";
import api from "../../../misc/api";
import { Box } from "@mui/material";
import Loading from "../../common/Loading";
import DataTable from "../../common/DataTable";
import { TITLE_PREFIX } from "../../../misc/constant";

export default function AllTransactions() {
  const [data, setData] = useState([]);
  const [openDetailsModal, setOpenDetailsModal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    document.title = TITLE_PREFIX + "Transactions";
  }, []);

  const deleteSelected = (data) => {
    if (typeof data === "string") console.log("single", data);
    else console.log("multiple", data);
  };

  const openDetails = async (id) => {
    await api
      .post("/transactions/id", { id })
      .then((res) => {
        const { data, success } = res.data;
        if (success) {
          setTransaction(data);
          setOpenDetailsModal(false);
        }
      })
      .catch((e) => {
        console.log("error occured", e);
      });
  };

  const getData = () => {
    setLoading(true);
    api
      .get("/transactions/all")
      .then((res) => {
        setData(res.data.data);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!openDetailsModal) setTransaction(null);
  }, [openDetailsModal]);

  const headCells = [
    {
      id: "reference",
      numeric: false,
      disablePadding: true,
      label: "Reference",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: false,
      label: "Amount",
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  return (
    <Box sx={{ m: 4 }}>
      {loading ? (
        <Loading />
      ) : (
        <DataTable
          deleteAction={deleteSelected}
          rows={data}
          openDetails={openDetails}
          columns={headCells}
        />
      )}
    </Box>
  );
}
