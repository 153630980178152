import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Heading, SubHeading } from "../../styled/styledTypography";
import { CurrencyPound, Refresh, Save } from "@mui/icons-material";
import citiesToCounties from "../../../misc/cities-to-counties.json";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../misc/api";
import { TITLE_PREFIX } from "../../../misc/constant";

function AddCollection() {
  const cities = Object.keys(citiesToCounties);

  const [data, setData] = useState({});
  const [itemsData, setItemsData] = useState(null);
  const [isLoading, setIsLoaing] = useState(false);

  const [alert, setAlert] = useState(null);

  const { state: LocationData } = useLocation();

  const navigate = useNavigate();

  const handleGenerateReference = () => {
    const timestamp = new Date().getTime();
    const ref = "IT-" + timestamp;
    setData({ ...data, ref });
  };

  useEffect(() => {
    document.title = TITLE_PREFIX + "Add Collection";
  }, []);

  useState(() => {
    if (LocationData) {
      data.contactName = LocationData?.senderName;
      data.email = LocationData?.senderEmail;
      data.id = LocationData?.id;
      handleGenerateReference();
    }
  }, []);

  useEffect(() => {
    if (data?.city) {
      const county = citiesToCounties[data?.city];
      setData({ ...data, county });
    }
  }, [data?.city]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => console.log(itemsData), [itemsData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const _data = { ...data };
    setIsLoaing(true);

    // const items = [
    //   {
    //     name: "Laptops",
    //     quantity: _data.laptops,
    //     collected: 0,
    //   },
    //   {
    //     name: "Computers",
    //     quantity: _data.computers,
    //     collected: 0,
    //   },
    //   {
    //     name: "Servers",
    //     quantity: _data.servers,
    //     collected: 0,
    //   },
    //   {
    //     name: "Printers",
    //     quantity: _data.printers,
    //     collected: 0,
    //   },
    //   {
    //     name: "TFT Monitors",
    //     quantity: _data.tftMonitors,
    //     collected: 0,
    //   },
    //   {
    //     name: "CRT Monitors",
    //     quantity: _data.crtMonitors,
    //     collected: 0,
    //   },
    //   {
    //     name: "Copiers",
    //     quantity: _data.copiers,
    //     collected: 0,
    //   },
    //   {
    //     name: "Tablet Phones",
    //     quantity: _data.tabletPhones,
    //     collected: 0,
    //   },
    //   {
    //     name: "Other Items",
    //     quantity: _data.otherItems,
    //     collected: 0,
    //   },
    // ];

    _data.items = itemsData;

    api
      .post("/collections/new", _data)
      .then((res) => {
        if (res.data.success) {
          setAlert({
            status: true,
            msg: "Collection Added Successfully",
            color: "success",
          });
          setTimeout(() => {
            navigate("/dashboard/collections/" + res.data.data._id);
          }, 500);
          setData({});
        } else {
          setAlert({
            status: true,
            msg: "Collection Failed to Add",
            color: "error",
          });
        }
      })
      .catch((err) => {
        setIsLoaing(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Snackbar
        open={alert?.status}
        autoHideDuration={5000}
        onClose={() => setAlert(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={alert?.color}>{alert?.msg}</Alert>
      </Snackbar>
      <Paper
        sx={{
          padding: 5,
          borderRadius: 3,
          border: "1px solid #000",
          my: 10,
          maxWidth: "90%",
        }}
        elevation={0}
      >
        <Heading textAlign={"center"} marginBottom={5}>
          Record a New Collection
        </Heading>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              columnGap: 5,
            }}
          >
            <ContactInfo
              data={data}
              setData={setData}
              handleChange={handleChange}
              cities={cities}
              handleGenerateReference={handleGenerateReference}
            />
            <Box
              sx={{
                borderRight: "1px solid #ccc",
                minHeight: "50%",
                maxHeight: "60%",
              }}
            ></Box>

            <ItemsInfo
              data={data}
              handleChange={handleChange}
              itemsData={itemsData}
              setItemsData={setItemsData}
            />

            <Box
              sx={{
                borderRight: "1px solid #ccc",
                minHeight: "50%",
                maxHeight: "60%",
              }}
            ></Box>

            <AdditionalInfo data={data} handleChange={handleChange} />
          </Box>
          <Button
            variant="contained"
            disableElevation
            sx={{
              // background: CompanyGreen,
              mt: 4,
              py: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: 1,
            }}
            color="success"
            type="submit"
            fullWidth
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              <>
                <Save />
                <Typography fontWeight={"bold"}>Save</Typography>
              </>
            )}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

const ContactInfo = ({
  handleChange,
  handleGenerateReference,
  data,
  cities,
  setData,
}) => {
  return (
    <Box>
      <SubHeading textAlign={"center"} mb={2}>
        Contact Info
      </SubHeading>
      <Box sx={{ display: "flex", mb: 4 }}>
        <TextField
          fullWidth
          name="ref"
          id="reference"
          label="Reference"
          placeholder="Reference"
          onChange={handleChange}
          value={data?.ref || ""}
        />
        <Button
          xs={{ height: "100%" }}
          type="button"
          disableElevation
          variant="contained"
          color="primary"
          onClick={handleGenerateReference}
        >
          <Refresh />
        </Button>
      </Box>
      <Grid container columnSpacing={2} rowSpacing={4}>
        <Grid item md={6} sm={12}>
          <TextField
            fullWidth
            name="contactName"
            id="contactName"
            label="Contact Name"
            placeholder="Contact Name"
            onChange={handleChange}
            value={data?.contactName}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <TextField
            fullWidth
            name="email"
            id="email"
            type="email"
            label="Email"
            placeholder="Email"
            onChange={handleChange}
            value={data?.email}
          />
        </Grid>

        <Grid item md={6} sm={12}>
          <TextField
            fullWidth
            name="contactNo"
            id="phoneNo"
            label="Phone No."
            placeholder="Phone Number"
            onChange={handleChange}
            value={data?.phoneNo}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <TextField
            fullWidth
            name="companyName"
            id="companyName"
            label="Company Name"
            placeholder="Company Name"
            onChange={handleChange}
            value={data?.companyName}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item md={6} sm={12}>
          <Autocomplete
            disablePortal
            id="city"
            name="city"
            options={cities}
            onChange={(e, value) => {
              setData({ ...data, city: value });
            }}
            renderInput={(params) => (
              <TextField {...params} label="City" name="city" />
            )}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <TextField
            key={data?.county}
            fullWidth
            name="county"
            id="county"
            label="County"
            placeholder="County"
            disabled
            value={data?.county}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <TextField
            fullWidth
            name="postCode"
            id="postCode"
            label="Post Code"
            placeholder="Post Code"
            onChange={handleChange}
            value={data?.postCode}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="address"
            id="address"
            label="Address"
            placeholder="Address"
            onChange={handleChange}
            value={data?.address}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const ItemsInfo = ({ data, handleChange, itemsData, setItemsData }) => {
  const [itemsLoading, setItemsLoading] = useState(true);
  const [itemsError, setItemsError] = useState(false);

  const handleItemChange = (item, quantity) => {
    const _items = itemsData.map((i) => {
      if (i?.itemId === item?.itemId) {
        return {
          itemId: item?.itemId,
          name: item.name,
          booked: quantity,
        };
      }
      return i;
    });

    setItemsData([..._items]);
  };

  const getItemsList = () => {
    setItemsLoading(true);
    api
      .post("items/all")
      .then((res) => {
        const { data: _items, success } = res.data;
        console.log(_items);
        if (success) {
          const mappedItems = _items?.map((i) => ({
            itemId: i?._id,
            name: i?.name,
            booked: null,
          }));
          setItemsData([
            ...mappedItems,
            {
              itemId: null,
              name: "Other Items",
              booked: null,
            },
          ]);
        } else {
          setItemsError(true);
        }
      })
      .finally(() => {
        setItemsLoading(false);
      });
  };

  useEffect(() => {
    getItemsList();
  }, []);

  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={12}>
        <SubHeading textAlign={"center"}>Items</SubHeading>
      </Grid>
      {itemsData?.map((item) => (
        <Grid item md={6} sm={12}>
          <TextField
            fullWidth
            name={item?.name}
            sx={(theme) => ({ textAlign: "center" })}
            id={item?.itemId}
            label={item?.name}
            type="number"
            placeholder={item?.name}
            onChange={(e) => handleItemChange(item, e.target.value)}
            value={item?.booked}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const AdditionalInfo = ({ data, handleChange }) => {
  return (
    <Grid container columnSpacing={2} rowSpacing={4}>
      <Grid item xs={12}>
        <SubHeading textAlign={"center"}>Additional Info</SubHeading>
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          fullWidth
          rows={15}
          name="comments"
          value={data?.comments}
          placeholder="Additional Comments"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Fee"
          id="fee"
          name="fee"
          type="number"
          onChange={handleChange}
          value={data?.fee}
          fullWidth
          step={"0.01"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CurrencyPound />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AddCollection;
