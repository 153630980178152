import { Box, CircularProgress } from "@mui/material";
import React from "react";

export default function Loading() {
  return (
    <Box
      sx={{
        textAlign: "center",
        margin: 1,
        width: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
