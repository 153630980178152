import { CurrencyPound, RotateLeft, Save } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  Snackbar,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import React, { useEffect, useState } from "react";
import { Heading } from "../../styled/styledTypography";
import api from "../../../misc/api";
import { TITLE_PREFIX } from "../../../misc/constant";

export default function AddTransaction() {
  const [data, setData] = useState({
    reference: "",
    date: Date.now(),
  });
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    document.title = TITLE_PREFIX + "Add New Transaction";
  }, []);

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await api
      .post("/transactions/new", {
        ...data,
      })
      .then((res) => {
        const status = res.data.success;
        if (status) {
          handleReset();
        }
        setAlert({
          msg: res.data.msg,
          color: status ? "success" : "error",
          status: true,
        });
      });
  };

  const handleReset = (e) => {
    setData({
      ...data,
      reference: "",
      amount: 0,
      description: "",
      categroy: "",
      type: "",
      date: null,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 10,
      }}
    >
      <Snackbar
        open={alert?.status}
        autoHideDuration={5000}
        onClose={() => setAlert(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={alert?.color}>{alert?.msg}</Alert>
      </Snackbar>
      <Paper
        sx={{
          padding: 5,
          borderRadius: 3,
        }}
        elevation={5}
      >
        <Heading textAlign={"center"} marginBottom={5}>
          Record a New Transaction
        </Heading>
        <Box
          component={"form"}
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "550px",
            rowGap: 5,
          }}
        >
          <TextField
            fullWidth
            name="reference"
            id="reference"
            label="Reference"
            placeholder="Reference"
            onChange={handleChange}
            value={data?.reference}
          />
          <TextField
            fullWidth
            value={data?.description}
            onChange={handleChange}
            name="description"
            multiline
            placeholder="Add some description for the transaction"
            rows={8}
          />
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Amount"
                id="amount"
                name="amount"
                type="number"
                onChange={handleChange}
                value={data?.amount}
                fullWidth
                step={"0.01"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyPound />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="transactionType">Type</InputLabel>
                <Select
                  fullWidth
                  labelId="transactionType"
                  value={data?.type}
                  label="Transaction Type"
                  name="type"
                  onChange={handleChange}
                >
                  <MenuItem value={"income"}>Income</MenuItem>
                  <MenuItem value={"expense"}>Expense</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box>
                  <DatePicker
                    onChange={(date) => {
                      setData({ ...data, date });
                    }}
                    format="DD/MM/YYYY"
                    sx={{
                      width: "100%",
                    }}
                    label="Date"
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="transactionCategory">Category</InputLabel>
                <Select
                  fullWidth
                  labelId="transactionCategory"
                  value={data?.categroy}
                  label="Category"
                  name="category"
                  onChange={handleChange}
                >
                  <MenuItem value={"sale"}>Sale</MenuItem>
                  <MenuItem value={"salary"}>Salary</MenuItem>
                  <MenuItem value={"bills"}>Bills</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              disableElevation
              color="info"
              type="button"
              onClick={handleReset}
            >
              <RotateLeft /> Reset
            </Button>
            <Button
              variant="contained"
              disableElevation
              color="success"
              type="submit"
            >
              <Save /> Save
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
