import {
  Box,
  Grid,
  Typography,
  Stack,
  Avatar,
  IconButton,
  TextField,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
export default function TopBar({
  showGreeting,
  setEndDate,
  setStartDate,
  endDate,
  startDate,
  loading,
  handleRefresh,
}) {
  const [user, setUser] = useState({});

  useEffect(() => {
    const _user = JSON.parse(localStorage.getItem("user")) || null;
    setUser({ ..._user });
  }, []);

  return (
    <Box sx={{ background: "#f9f9f9", borderRadius: 2, boxShadow: 1, p: 3 }}>
      {showGreeting && (
        <Grid container alignItems="center" spacing={2}>
          <Grid item md={6}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar sx={{ bgcolor: "#161925", width: 56, height: 56 }}>
                {user?.name?.[0]}
              </Avatar>
              <Box>
                <Typography
                  variant="h4"
                  sx={{ color: "#161925", fontWeight: 700 }}
                >
                  Hi, {user?.name}!
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: 18,
                    color: "text.secondary",
                  }}
                >
                  Here's your report for today.
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item md={6}>
            {!loading && (
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    defaultValue={dayjs(startDate)}
                    onChange={(value) => setStartDate(value)}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" size="small" />
                    )}
                  />
                  <DesktopDatePicker
                    defaultValue={dayjs(endDate)}
                    onChange={(value) => setEndDate(value)}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" size="small" />
                    )}
                  />
                </LocalizationProvider>
                <IconButton color="#161925" onClick={handleRefresh}>
                  <Refresh />
                </IconButton>
              </Stack>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
