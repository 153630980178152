import React from "react";
import Dashboard from "../components/Dashboard";

export default function Home() {
  return (
    <div>
      <Dashboard />
    </div>
  );
}
