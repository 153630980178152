import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Heading } from "../../../styled/styledTypography";
import Loading from "../../../common/Loading";
import { Delete, Edit } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../../misc/api";

export default function UsersTable() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const getRoleString = (role) => {
    let userString;
    switch (role) {
      case 0:
        userString = "Root Administrator";
        break;
      case 1:
        userString = "Office Admnisitrator";
        break;
      case 2:
        userString = "Receptionist";
        break;
      case 3:
        userString = "Driver";
        break;
      default:
        userString = "Invalid Role";
        break;
    }
    return userString;
  };

  const getUsers = () => {
    api
      .get("/users/all")
      .then((res) => {
        setUsers(res.data.data);
        console.log(res.data.data);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    getUsers();
    setLoading(false);
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Heading>Users</Heading>
        <Link to={"/dashboard/new-user"}>
          <Button variant="contained" color="success">
            Add User
          </Button>
        </Link>
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <TableContainer
          sx={{
            margin: "20px 0",
            border: "1px solid #ccc",
            padding: "10px 0",
          }}
        >
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: 20 }}>Name</TableCell>
                <TableCell sx={{ fontSize: 20 }}>Email</TableCell>
                <TableCell sx={{ fontSize: 20 }}>Role</TableCell>
                <TableCell sx={{ fontSize: 20 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row) => (
                <TableRow
                  hover
                  key={row._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{getRoleString(row.role)}</TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      columnGap: 2,
                    }}
                  >
                    <Link to={"/dashboard/edit-user/" + row._id}>
                      <Edit
                        sx={{
                          color: "warning",
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
