import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";

export default function DetailsModal({
  open,
  handleClose,
  data,
  handleMarkAsRead,
}) {
  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
          Call Log Details
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText color={"black"}>
          <Typography mb={2}>
            <b>Reference:</b> {data?.reference}
          </Typography>
          <Typography fontWeight={"bold"} mb={1}>
            Description:
          </Typography>
          {data?.description}
        </DialogContentText>
        <Divider sx={{ my: 2 }} />
        <Box
          mt={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography>
            <b>Phone No:</b> {data?.phoneNo}
          </Typography>
          <Typography>
            <b>Contact Name:</b> {data?.contactName}
          </Typography>
          <Typography>
            <b>Company:</b> {data?.company}
          </Typography>
          <Typography>
            <b>Data & Time:</b>{" "}
            {moment(data?.date).format("DD-MM-YYYY hh:mm a")}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => {
            data?.read ? handleClose() : handleMarkAsRead(data?._id);
          }}
        >
          {data?.read ? "Close" : "Mark as Read"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
