const LOGO_URL =
  "https://computerwaste.co.uk/wp-content/uploads/2024/01/CWR-1024x166.png";
const LOGO_URL_OLD =
  "https://computerdatashred.co.uk/wp-content/uploads/2023/11/logo-1.png";

const TITLE_PREFIX = "Computer Datashred Admin - ";

module.exports = {
  LOGO_URL,
  TITLE_PREFIX,
};
