import React from 'react';
import TextField from '@mui/material/TextField';
import { DatePicker } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { styled } from '@mui/material/styles';

// Styled TextField for custom input
const CustomTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& input': {
    padding: '10px',
  },
  '& .MuiInputBase-input': {
    padding: '10px',
  },
}));

// Custom Input Component
const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
  <CustomTextField
    ref={ref}
    value={value}
    onClick={onClick}
    InputProps={{ disableUnderline: true }}
    variant="outlined"
    placeholder="Select date"
  />
));

export default CustomDateInput;
