import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../../misc/api";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Alert,
  Snackbar,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  Checkbox,
} from "@mui/material";
import Loading from "../../common/Loading";
import { DetailsBox, Heading, SubHeading } from "../../styled/styledTypography";
import moment from "moment";
import { Loader } from "@googlemaps/js-api-loader";
import CertificateCard from "../../common/CertificateCard";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  CheckBox,
  CurrencyPound,
  ModeEdit,
  Restore,
  Save,
} from "@mui/icons-material";
import BookingModal from "./BookingModal";
import { mapItems } from "../../../misc/utils";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export default function CollectionDetail() {
  let { id } = useParams();
  const { state } = useLocation();

  const [loading, setLoading] = useState(true);
  const [collection, setCollection] = useState(null);
  const [address, setAddress] = useState(null);
  const [mapError, setMapError] = useState(null);
  const [invoice, setInvoice] = useState({});

  const getCollection = async () => {
    setLoading(true);
    api
      .post(`/collections/id`, { id })
      .then((res) => {
        const _data = res.data.data;
        console.log(res.data.data.items);
        setCollection(_data);
        console.log("Data from Server", _data);
        const address = `${_data?.address}, ${_data?.city}, ${_data?.county}, ${_data?.postCode}`;
        setAddress(address);
        if (state?.convert_to_booking) {
          getItemsList(_data);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getItemsList = (currentCollection = null) => {
    if (!currentCollection) return;
    setLoading(true);
    api
      .post("items/all")
      .then((res) => {
        const { data: _items, success } = res.data;
        if (success) {
          const mappedItems = mapItems(_items);

          setCollection({
            ...currentCollection,
            items: mappedItems,
          });

          // setItemsData([
          //   ...mappedItems,
          //   {
          //     itemId: null,
          //     name: "Other Items",
          //     booked: null,
          //   },
          // ]);

          // const _collection = currentCollection;
          // _collection.items = mappedItems.map((item) => {
          //   debugger;
          //   const index = _collection?.items.indexOf(
          //     (i) => item.itemId === i?.itemId
          //   );
          //   const itemFromCollection = _collection?.items[index];

          //   if (index > 0) {
          //     return {
          //       ...itemFromCollection,
          //       name: itemFromCollection?.name || item?.name,
          //       booked: itemFromCollection?.booked || 0,
          //       collected: itemFromCollection?.collected || 0,
          //     };
          //   } else {
          //     return {
          //       itemId: item?.itemId,
          //       name: item?.name,
          //       booked: item?.booked || 0,
          //       collected: item?.collected || 0,
          //     };
          //   }
          // });
          // console.log("collection items", _collection.items);
          // setCollection(_collection);
        } else {
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadGoogleMap = () => {
    loader
      .load()
      .then((google) => {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address }, function (results, status) {
          if (status === "OK") {
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();
            const div = document.getElementById("map");

            const mapOptions = {
              center: {
                lat,
                lng,
              },
              zoom: 12,
            };
            var map = new google.maps.Map(div, mapOptions);
            var marker = new google.maps.Marker({
              map,
              position: results[0].geometry.location,
              label: {
                text: `${collection?.companyName}`,
                color: "black",
                fontSize: "16px",
                className: "marker-position",
              },
            });
          } else {
            throw new Error("Error loading map");
          }
        });
      })
      .catch((e) => {
        console.log("error", e);
        setMapError(true);
      });
  };

  useEffect(() => {
    if (address) {
      loadGoogleMap();
    }
  }, [address]);

  useEffect(() => {
    getCollection();
  }, [id]);

  useEffect(() => {
    setInvoice(collection?.certs?.invoice);
  }, [collection]);

  return (
    <Box sx={{ p: 4 }}>
      {loading ? (
        <Loading />
      ) : (
        <Details
          invoice={invoice}
          setLoading={setLoading}
          collection={collection}
          mapError={mapError}
          setCollection={setCollection}
          getCollection={getCollection}
          id={id}
        />
      )}
    </Box>
  );
}

const loader = new Loader({
  apiKey: GOOGLE_API_KEY,
  version: "weekly",
  libraries: ["places"],
});

const Details = ({
  setCollection,
  collection,
  mapError,
  setLoading,
  getCollection,
  invoice,
  id,
}) => {
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [selectedCert, setSelectedCert] = useState(null);
  const [clientEmail, setClientEmail] = useState(null);
  const [alert, setAlert] = useState(null);
  const [scheduleDialog, setScheduleDialog] = useState(false);
  const [scheduledOn, setScheduledOn] = useState(null);
  const [certLoading, setCertLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openBookingModal, setOpenBookingModal] = useState(false);
  const [isLoading, setIsLoaing] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState(false);

  const navigate = useNavigate();

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleUndo = () => {
    window.location.reload();
  };

  const handleSaveEdit = () => {
    setIsLoaing(true);
    setIsEdit(false);

    api
      .post("collections/update", collection)
      .then((res) => {
        console.log(res);
        setIsLoaing(false);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleChange = (e) =>
    setCollection({ ...collection, [e.target.name]: e.target.value });

  const handleSendEmail = async (e) => {
    console.log("sending email");
    e.preventDefault();
    let path = "";
    let fileName = "";
    if (selectedCert === "Destruction") {
      path = "/mail/pdf/destruction";
      fileName = "Destruction Certificate";
    } else if (selectedCert === "Duty Of Care") {
      path = "/mail/pdf/duty";
      fileName = "Duty of Care Cert";
    } else {
      path = "/mail/pdf/waste";
      fileName = "Waste";
    }
    setCertLoading(true);
    api
      .post(path, {
        id: collection._id,
        sendMail: clientEmail,
      })
      .then((res) => {
        if (res.data.success) {
          setAlert({
            status: true,
            msg: `${fileName} Certificate has been successfully emailed to ${clientEmail}`,
            color: "success",
          });
        }
      })
      .catch((e) =>
        setAlert({
          status: true,
          msg: `Error emailing Duty of Care Certificate to ${clientEmail}, try again.`,
          color: "error",
        })
      )
      .finally(() => {
        setCertLoading(false);
      });
    setShowEmailDialog(false);
  };

  const handleEmail = (cert) => {
    setSelectedCert(cert);
    setClientEmail(collection?.email);
    setShowEmailDialog(true);
  };

  const handleDutyOfCare = async () => {
    try {
      setCertLoading(true);
      await api.post("/mail/pdf/duty", { id: collection._id }).then((res) => {
        getCollection();
        setAlert({
          msg: "Duty of Care generated successfully!",
          color: "success",
          status: true,
        });
      });
    } catch (error) {
      console.error(error);
    } finally {
      setCertLoading(false);
    }
  };

  const handleGenerateDestructionCert = () => {
    navigate("/dashboard/generate-destruction", {
      state: { id: collection._id },
    });
  };

  const handleWasteCert = async () => {
    try {
      setCertLoading(true);
      const response = await api.post("/mail/pdf/waste", {
        id: collection._id,
      });
      const _data = response.data;
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleInvoice = async () => {
    try {
      setIsLoaing(true);
      const response = await api.post("/mail/pdf/collection-invoice", {
        id: collection._id,
      });
      const _data = response.data;
      window.location.reload();
      setIsLoaing(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadDutyOfCare = async () => {
    try {
      setCertLoading(true);
      await api
        .post(
          "/mail/pdf/duty",
          { id: collection._id },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          if (!res.status === 200) {
            setAlert({
              status: true,
              msg: "Error downloading Duty Of Care, please try again",
              color: "error",
            });
          } else {
            setAlert({
              status: true,
              msg: "Download Started",
              color: "success",
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${collection.ref} - Duty Of Care Certificate.pdf`
            );

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            setCertLoading(false);
          }
        });
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleDownloadDestruction = async () => {
    try {
      setCertLoading(true);

      const response = await api.post(
        "/mail/pdf/destruction",
        { id: collection._id },
        {
          responseType: "blob",
        }
      );

      setCertLoading(false);

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${collection.ref} - Certificate of Destruction.pdf`
      );

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleDownloadWaste = async () => {
    try {
      setCertLoading(true);
      const response = await api.post(
        "/mail/pdf/waste",
        { id: collection._id },
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${collection?.ref} - Hazardous Waste Consignment Note.pdf`
      );

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setCertLoading(false);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleDownloadInvoice = async () => {
    try {
      setLoading(true);
      const response = await api.post(
        "/mail/pdf/collection-invoice",
        { id: collection._id },
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Invoice - ${collection.ref}.pdf`);

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setLoading(false);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const getCollectionStatus = (id) => {
    let string;

    switch (id) {
      case 0:
        string = "Booked/Waiting to be Scheduled";
        break;
      case 1:
        string = "Scheduled for Collection";
        break;
      case 2:
        string = "Collected - Waiting to be Processed";
        break;
      case 3:
        string = "Invoiced";
        break;
      case 5:
        string = "Quote Sent";
        break;
      case 6:
        string = "Enquiry Recieved";
        break;
      default:
        string = null;
    }

    return string;
  };

  const handleSchedule = () => {
    api
      .post("/collections/schedule", {
        id: collection?._id,
        date: moment(scheduledOn).format("DD-MM-YYYY"),
        sendEmail: confirmEmail,
      })
      .then((res) => {
        const _collection = res.data.data;

        setCollection(_collection);

        setAlert({
          status: true,
          msg:
            collection.ref +
            " has been scheduled for " +
            moment(_collection.scheduledOn).format("DD / MM / YYYY"),
          color: "success",
        });
      })
      .catch((e) => {
        console.log("error while confirming");
      })
      .finally((_) => setScheduleDialog(false));
  };

  const handleInvoicePaid = async () => {
    const url = "/collections/invoice-paid";
    await api.post(url, { id: collection._id }).then((res) => {
      if (res.data.success) {
        window.location.reload();
      } else {
        setAlert({
          msg: res.data.msg,
          color: "error",
        });
      }
    });
  };

  const handleItemsChange = async (index, e) => {
    const _collection = collection;
    console.log(_collection.items[index]);
    _collection.items[index] = {
      ..._collection.items[index],
      [e.target.name]: e.target.value,
    };
    setCollection({ ..._collection });
    console.log(_collection.items[index]);
  };

  const handleBookCollection = () => {
    setOpenBookingModal(true);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Box>
      <BookingModal
        id={collection?._id}
        isOpen={openBookingModal}
        handleClose={() => setOpenBookingModal(false)}
      />
      <Snackbar
        open={alert?.status}
        autoHideDuration={5000}
        onClose={() => setAlert(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={alert?.color}>{alert?.msg}</Alert>
      </Snackbar>
      <Dialog
        fullWidth={true}
        open={showEmailDialog}
        onClose={() => setShowEmailDialog(false)}
        PaperProps={{
          component: "form",
          onSubmit: handleSendEmail,
        }}
      >
        <DialogTitle>Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter Client's Email to send {selectedCert}.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            value={clientEmail}
            variant="standard"
            onChange={(e) => setClientEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => setShowEmailDialog(false)}>
            Cancel
          </Button>
          <Button type="submit">Send</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={scheduleDialog}
        onClose={() => setScheduleDialog(false)}
        fullWidth
      >
        <DialogTitle id="schedule-dialog">
          <Heading>Schedule Collection</Heading>
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                format="DD/MM/YYYY"
                label="Schedule Date"
                value={scheduledOn}
                sx={{
                  width: "100%",
                }}
                onChange={(date) => setScheduledOn(date)}
              />
            </DemoContainer>
          </LocalizationProvider>
          <DialogContentText
            color={"error"}
            sx={{ mt: 5 }}
            id="schedule-dailog-description"
          >
            <Checkbox
              checked={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.checked)}
            />{" "}
            A confirmation email will be sent to {collection?.email}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="error"
            onClick={() => setScheduleDialog(false)}
            autoFocus
          >
            Cancel
          </Button>
          <Button variant="contained" color="success" onClick={handleSchedule}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Heading>Collection Details</Heading>
        {isEdit ? (
          <Box
            sx={{
              display: "flex",
              columnGap: 5,
            }}
          >
            <Button onClick={handleUndo} variant="contained" color="error">
              <Restore sx={{ mr: 1 }} />
              Undo Changes
            </Button>
            <Button
              onClick={handleSaveEdit}
              variant="contained"
              color="success"
            >
              <Save sx={{ mr: 1 }} />
              Save Changes
            </Button>
          </Box>
        ) : (
          <Button
            variant="contained"
            color="warning"
            sx={{
              background: "#FFBF00",
            }}
            onClick={handleEdit}
          >
            <ModeEdit sx={{ mr: 1 }} />
            Edit
          </Button>
        )}
      </Box>
      <Paper>
        <Box sx={{ mt: 2, p: 4, pb: 1 }}>
          <Grid container columnSpacing={3}>
            <Grid item md={6}>
              <DetailsBox>
                <Box>
                  <SubHeading>Contact Name</SubHeading>
                  {isEdit ? (
                    <TextField
                      fullWidth
                      name="contactName"
                      size="small"
                      value={collection?.contactName}
                      onChange={handleChange}
                    />
                  ) : (
                    <Box>{collection?.contactName}</Box>
                  )}
                </Box>
                <Box>
                  <SubHeading>Company Name</SubHeading>
                  {isEdit ? (
                    <TextField
                      fullWidth
                      name="companyName"
                      size="small"
                      value={collection?.companyName}
                      onChange={handleChange}
                    />
                  ) : (
                    <Box>{collection?.companyName}</Box>
                  )}
                </Box>
                <Box>
                  <SubHeading>Customer Phone</SubHeading>
                  <Box>
                    {isEdit ? (
                      <TextField
                        fullWidth
                        name="contactNo"
                        size="small"
                        value={collection?.contactNo}
                        onChange={handleChange}
                      />
                    ) : (
                      collection?.contactNo
                    )}
                  </Box>
                </Box>
              </DetailsBox>
            </Grid>
            <Grid item md={6}>
              <DetailsBox>
                <Box>
                  <SubHeading>Reference</SubHeading>
                  <Box>{collection?.ref}</Box>
                </Box>
                <Box>
                  <SubHeading>Booked On</SubHeading>
                  <Box>
                    {moment(collection?.bookedOn).format("DD / MM / YYYY")}
                  </Box>
                </Box>
                <Box>
                  <SubHeading>Scheduled For</SubHeading>
                  <Box>
                    {collection?.scheduledOn ? (
                      moment(collection?.scheduledOn).format("DD / MM / YYYY")
                    ) : (
                      <Typography
                        component={"a"}
                        sx={{
                          cursor: "pointer",
                        }}
                        color="error"
                        onClick={() => setScheduleDialog(true)}
                      >
                        To Be Scheduled
                      </Typography>
                    )}
                  </Box>
                </Box>
              </DetailsBox>
            </Grid>

            <Grid item md={6} sx={{ my: 2 }}>
              <DetailsBox>
                <Box>
                  <SubHeading>Fee</SubHeading>
                  <Box>
                    {isEdit ? (
                      <TextField
                        fullWidth
                        name="fee"
                        size="small"
                        type="number"
                        value={collection?.fee}
                        onChange={handleChange}
                        step={"0.01"}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CurrencyPound />
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : collection?.fee ? (
                      `£${collection?.fee}`
                    ) : (
                      "Free Collection"
                    )}
                  </Box>
                </Box>
              </DetailsBox>
            </Grid>

            <Grid md={12}>
              <Box ml={3} mt={2}>
                <SubHeading>Collection Status:</SubHeading>
                <Typography>
                  {isEdit ? (
                    <Select
                      sx={{
                        width: "50%",
                      }}
                      value={collection?.status}
                      name="status"
                      onChange={handleChange}
                    >
                      <MenuItem value={6}>Enquiry Recieved</MenuItem>
                      <MenuItem value={5}>Quote Sent</MenuItem>
                      <MenuItem value={0}>
                        Booked/Waiting to be Scheduled
                      </MenuItem>
                      <MenuItem disabled value={1}>
                        Scheduled for Collection
                      </MenuItem>
                      <MenuItem value={2}>
                        Collected - Waiting to be Processed
                      </MenuItem>
                      <MenuItem value={3}>Invoiced</MenuItem>
                    </Select>
                  ) : (
                    getCollectionStatus(collection?.status)
                  )}
                </Typography>
              </Box>
            </Grid>

            <Grid md={12}>
              <Divider
                sx={{
                  my: 5,
                }}
              />
            </Grid>
            <Grid item md={6}>
              <DetailsBox>
                <Box>
                  <SubHeading>Address</SubHeading>
                  <Box>
                    {isEdit ? (
                      <TextField
                        fullWidth
                        name="address"
                        size="small"
                        value={collection?.address}
                        onChange={handleChange}
                      />
                    ) : (
                      collection?.address
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box>
                    <SubHeading>Post Code</SubHeading>
                    <Box>
                      {isEdit ? (
                        <TextField
                          fullWidth
                          name="postCode"
                          size="small"
                          value={collection?.postCode}
                          onChange={handleChange}
                        />
                      ) : (
                        collection?.postCode
                      )}
                    </Box>
                  </Box>
                </Box>
              </DetailsBox>
            </Grid>
            <Grid md={12}>
              <Divider
                sx={{
                  my: 5,
                }}
              />
            </Grid>
            <Grid item md={12}>
              <DetailsBox>
                <Box>
                  <SubHeading sx={{ mb: 1 }}> Invoice</SubHeading>
                  <Box>
                    {invoice?.generated ? (
                      "Generated on " +
                      moment(invoice.invoiceGeneratedOn).format(
                        "DD / MM / YYYY"
                      )
                    ) : (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={handleInvoice}
                      >
                        Generate Invoice
                      </Button>
                    )}
                  </Box>
                </Box>
                {invoice?.generated && (
                  <>
                    <Box>
                      <SubHeading>Invoice Amount</SubHeading>
                      <Box>£{invoice.invoiceAmount} (Incl. 20% VAT)</Box>
                    </Box>

                    <Box>
                      <SubHeading>Invoice Status</SubHeading>
                      <Box>
                        {invoice.status === 1
                          ? "Unpaid " +
                            (collection?.invoiceOverdue ? "(Overdue)" : "")
                          : "Paid on " +
                            moment(invoice?.invoicePaidDate).format(
                              "DD / MM / YYYY"
                            )}
                      </Box>
                    </Box>

                    <Box>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={handleDownloadInvoice}
                      >
                        Download Invoice
                      </Button>
                    </Box>
                    {invoice.status !== 2 && (
                      <Box>
                        <Button variant="contained" onClick={handleInvoicePaid}>
                          Invoice Paid
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </DetailsBox>
            </Grid>
            <Grid md={12}>
              <Divider
                sx={{
                  my: 5,
                }}
              />
            </Grid>

            <Grid item md={12}>
              {collection?.status > 3 ? ( // Enquiry or Quote Sent
                <Box
                  sx={{
                    m: "auto",
                    width: "100%",
                  }}
                >
                  <SubHeading
                    sx={{
                      mb: 1,
                    }}
                  >
                    Items
                  </SubHeading>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleBookCollection}
                  >
                    Book for Collection
                  </Button>
                </Box>
              ) : (
                <ItemsTable
                  collection={collection}
                  handleItemsChange={handleItemsChange}
                  isEdit={isEdit}
                />
              )}
            </Grid>

            <Grid md={12}>
              <Divider
                sx={{
                  my: 5,
                }}
              />
            </Grid>

            <Grid item md={12}>
              {mapError ? (
                <Box sx={{ color: "red" }}>Error loading map</Box>
              ) : (
                <Box
                  id="map"
                  className="map"
                  sx={{
                    mt: 5,
                    minHeight: "400px",
                    minWidth: "100%",
                  }}
                ></Box>
              )}
            </Grid>

            <Grid md={12}>
              <Divider
                sx={{
                  my: 5,
                }}
              />
            </Grid>
            <Grid item md={12}>
              <Box
                sx={{
                  mt: 5,
                }}
              >
                <SubHeading>Certificates</SubHeading>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 5,
                  }}
                >
                  <CertificateCard
                    data={collection?.certs.duty}
                    title={"Duty Of Care"}
                    generateHanlder={handleDutyOfCare}
                    emailHandler={handleEmail}
                    download={handleDownloadDutyOfCare}
                    loading={certLoading}
                  />
                  <CertificateCard
                    data={collection?.certs.destruction}
                    title={"Destruction"}
                    generateHanlder={handleGenerateDestructionCert}
                    emailHandler={handleEmail}
                    download={handleDownloadDestruction}
                    loading={certLoading}
                  />
                  <CertificateCard
                    data={collection?.certs.waste}
                    title={"Waste"}
                    generateHanlder={handleWasteCert}
                    emailHandler={handleEmail}
                    download={handleDownloadWaste}
                    loading={certLoading}
                  />
                  {/* <CertificateCard
                    data={collection?.certs.invoice}
                    title={"Invoice"}
                    handler={handleInvoice}
                    emailHandler={handleEmail}
                    download={handleDownloadInvoice}
                    loading={certLoading}
                  /> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

const ItemsTable = ({ collection, handleItemsChange, isEdit }) => {
  return (
    <Box sx={{}}>
      <TableContainer>
        <Table
          sx={{
            minWidth: "500px",
            border: "1px solid black",
            p: 1,
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {" "}
                Item Type
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Quantity Booked
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Quantity Collected
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              background: "#FEFDED",
            }}
          >
            {collection?.items?.map((item, index) => (
              <TableRow
                hover={true}
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {item.name}
                </TableCell>
                <TableCell scope="row">
                  {isEdit ? (
                    <TextField
                      label={item.name}
                      value={item.booked}
                      name={"booked"}
                      type="number"
                      onChange={(e) => handleItemsChange(index, e)}
                    />
                  ) : (
                    item.booked
                  )}
                </TableCell>
                <TableCell scope="row">
                  {isEdit ? (
                    <TextField
                      label={item.name}
                      value={item?.collected}
                      name={"collected"}
                      type="number"
                      onChange={(e) => handleItemsChange(index, e)}
                    />
                  ) : (
                    item.collected
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
