import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Heading } from "../../../styled/styledTypography";
import Loading from "../../../common/Loading";
import { Close, Delete, Edit } from "@mui/icons-material";
import api from "../../../../misc/api";

function ItemsManagement() {
  const itemTemplate = {
    _id: null,
    name: "",
    weight: "",
    ewcCode: "",
    component: "",
    concentration: "",
    hazardCode: "",
    pdfs: {
      dutyOfCare: false,
      destruction: false,
      waste: false,
      invoice: false,
    },
  };

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [addUpdateModal, setAddUpdateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(itemTemplate);
  const [modalType, setModalType] = useState(0); // 0 = ADD, 1 = UPDATE

  const getItems = () => {
    setLoading(true);
    api.post("items/all").then((res) => {
      if (res.data.success) {
        setItems([...res.data.data]);
        setLoading(false);
      }
    });
  };
  useEffect(() => getItems(), []);

  const handleModalClose = () => {
    console.log("Modal Close");
    setAddUpdateModal(false);
  };

  const handleUpdate = (data) => {
    setModalType(1);
    setSelectedItem(data);
    setAddUpdateModal(true);
  };

  const handleAddNew = () => {
    setSelectedItem(itemTemplate);
    setModalType(0);
    setAddUpdateModal(true);
  };

  const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    fontSize: 17,
    fontWeight: "bold",
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: 16,
  }));

  return (
    <Box>
      <AddUpdateItemsModal
        data={selectedItem}
        type={modalType}
        open={addUpdateModal}
        handleClose={handleModalClose}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Heading>Items</Heading>
        <Button variant="contained" color="success" onClick={handleAddNew}>
          Add Item
        </Button>
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <TableContainer
          sx={{
            margin: "20px 0",
            border: "1px solid #ccc",
            padding: "10px 0",
          }}
        >
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <StyledTableHeadCell>Name</StyledTableHeadCell>
                <StyledTableHeadCell>Weight</StyledTableHeadCell>
                <StyledTableHeadCell>Component</StyledTableHeadCell>
                <StyledTableHeadCell>Concentration</StyledTableHeadCell>
                <StyledTableHeadCell>Physical Form</StyledTableHeadCell>
                <StyledTableHeadCell>EWC Code</StyledTableHeadCell>
                <StyledTableHeadCell>Hazard Code</StyledTableHeadCell>
                <StyledTableHeadCell>Show on Duty of Care</StyledTableHeadCell>
                <StyledTableHeadCell>Show on Hazard Waste</StyledTableHeadCell>
                <StyledTableHeadCell>Show on Destruction</StyledTableHeadCell>
                <StyledTableHeadCell>Show on Invoice</StyledTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.length < 1 && (
                <TableRow>
                  <TableCell colSpan={11}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      No Items found.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {items.map((row) => (
                <TableRow
                  hover
                  key={row._id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => handleUpdate(row)}
                >
                  <StyledTableCell component="th" scope="row" sx={{}}>
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell>{row?.weight || " - "}</StyledTableCell>
                  <StyledTableCell>{row?.component || " - "}</StyledTableCell>
                  <StyledTableCell>
                    {row?.concentration || " - "}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row?.physicalForm || " - "}
                  </StyledTableCell>
                  <StyledTableCell>{row?.ewcCode || " - "}</StyledTableCell>
                  <StyledTableCell>{row?.hazardCode || " - "}</StyledTableCell>
                  <StyledTableCell>
                    <Checkbox disabled checked={row?.pdfs?.dutyOfCare} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Checkbox disabled checked={row?.pdfs?.waste} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Checkbox disabled checked={row?.pdfs?.destruction} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Checkbox disabled checked={row?.pdfs?.invoice} />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}

const AddUpdateItemsModal = ({ data, type, open, handleClose }) => {
  const [modalData, setModalData] = useState(null);
  const [showError, setShowError] = useState(false);

  const handleSave = async () => {
    setShowError(false);
    const url = type === 0 ? "items/new" : "items/update";
    const res = await api.post(url, modalData);
    if (res.data.success) {
      window.location.reload();
    } else {
      setShowError(true);
    }
  };

  const handleDelete = async () => {
    setShowError(false);
    const url = "items/delete";
    const res = await api.post(url, { id: modalData?._id });
    if (res.data.success) {
      window.location.reload();
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    console.log(data);
    setModalData(data);
  }, [data]);

  const handleChange = (e) =>
    setModalData({
      ...modalData,
      [e.target.name]: e.target.value,
    });

  const handleCheckboxChange = (e) => {
    const pdfs = {
      ...modalData?.pdfs,
      [e.target.name]: e.target.checked,
    };
    setModalData({
      ...modalData,
      pdfs,
    });
  };

  const StyledChecboxContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
  }));

  const StyledCheckboxTitle = styled(Typography)(({ theme }) => ({
    width: "100%",
    fontWeight: "bold",
  }));

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {type === 0 ? "Add" : "Update"} Item
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            rowGap: 2,
            flexDirection: "column",
          }}
        >
          <TextField
            name="name"
            onChange={handleChange}
            label="Name"
            size="small"
            value={modalData?.name}
            fullWidth
          />
          <TextField
            name="weight"
            onChange={handleChange}
            label="Weight"
            size="small"
            value={modalData?.weight}
            fullWidth
          />

          <TextField
            name="ewcCode"
            onChange={handleChange}
            label="EWC Code"
            size="small"
            value={modalData?.ewcCode}
            fullWidth
          />
          <TextField
            name="component"
            onChange={handleChange}
            label="Component"
            size="small"
            value={modalData?.component}
            fullWidth
          />
          <TextField
            name="physicalForm"
            onChange={handleChange}
            label="Physical Form"
            size="small"
            value={modalData?.physicalForm}
            fullWidth
          />
          <TextField
            name="concentration"
            onChange={handleChange}
            label="Concentration"
            size="small"
            value={modalData?.concentration}
            fullWidth
          />
          <TextField
            name="hazardCode"
            onChange={handleChange}
            label="Hazard Code"
            size="small"
            value={modalData?.hazardCode}
            fullWidth
          />

          <StyledChecboxContainer>
            <StyledCheckboxTitle>Show on Duty Of Care</StyledCheckboxTitle>
            <Checkbox
              checked={modalData?.pdfs?.dutyOfCare}
              name="dutyOfCare"
              onChange={handleCheckboxChange}
            />
          </StyledChecboxContainer>
          <StyledChecboxContainer>
            <StyledCheckboxTitle>
              Show on Certificate Of Destruction
            </StyledCheckboxTitle>
            <Checkbox
              checked={modalData?.pdfs?.destruction}
              name="destruction"
              onChange={handleCheckboxChange}
            />
          </StyledChecboxContainer>
          <StyledChecboxContainer>
            <StyledCheckboxTitle>Show on Hazerdous Waste</StyledCheckboxTitle>
            <Checkbox
              checked={modalData?.pdfs?.waste}
              name="waste"
              onChange={handleCheckboxChange}
            />
          </StyledChecboxContainer>
          <StyledChecboxContainer>
            <StyledCheckboxTitle>Show on Invoice</StyledCheckboxTitle>
            <Checkbox
              checked={modalData?.pdfs?.invoice}
              name="invoice"
              onChange={handleCheckboxChange}
            />
          </StyledChecboxContainer>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: showError && "space-between",
        }}
      >
        {showError && (
          <Typography color={"red"}>There was an error, try again.</Typography>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            columnGap: 2,
          }}
        >
          {type === 1 && (
            <Button variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
          )}

          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ItemsManagement;
