import { Alert, Box, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loading from "../../common/Loading";
import CallLogTable from "./Components/Table";
import DetailsModal from "./Components/DetailsModal";
import api from "../../../misc/api";
import DeleteCallLogModal from "./Components/DeleteModal";
import { TITLE_PREFIX } from "../../../misc/constant";

export default function CallLogComponent() {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    document.title = TITLE_PREFIX + "Call Logs";
  }, []);

  const headCells = [
    {
      id: "reference",
      numeric: false,
      disablePadding: false,
      label: "Reference",
    },
    {
      id: "contactName",
      numeric: false,
      disablePadding: false,
      label: "Contact Name",
    },
    {
      id: "company",
      numeric: false,
      disablePadding: false,
      label: "Company",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "phoneNo",
      numeric: false,
      disablePadding: false,
      label: "Phone No.",
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  const getData = () => {
    setLoading(true);
    api
      .post("/call-log/all")
      .then((res) => {
        setTableData(res?.data?.data);
        console.log("res", res.data);
      })
      .finally((_) => {
        setLoading(false);
      });
  };

  const handleOpenDetail = (data) => {
    setSelectedData(data);
    setOpenDetails(true);
  };

  const handleMarkAsRead = async (id) => {
    await api
      .post("/call-log/mark-as-read", { _id: id })
      .then((res) => {
        setAlert({
          status: true,
          msg: res.data.msg,
          color: "success",
        });
        setOpenDetails(false);
        getData();
      })
      .catch((err) => {
        setAlert({
          status: true,
          msg: err.response.data.msg,
          color: "error",
        });
      });
  };

  const handleDelete = async (data) => {
    setDeleteId(data);
    setOpenDeleteModal(true);

    // const res = await api.post("/call-log/delete", { id: data });
    // console.log(res);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);

    const res = await api.post("/call-log/delete", { id: deleteId });

    if (res.data.success) {
      let _data = [...tableData];
      _data = _data.filter((i) => i._id !== deleteId);
      setTableData(_data);
    }

    setOpenDeleteModal(false);
    setLoading(false);
  };

  useEffect(() => getData(), []);

  return (
    <>
      <Snackbar
        open={alert?.status}
        autoHideDuration={5000}
        onClose={() => setAlert(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={alert?.color}>{alert?.msg}</Alert>
      </Snackbar>
      <Box sx={{ m: 4 }}>
        {loading ? (
          <Loading />
        ) : (
          <CallLogTable
            deleteAction={handleDelete}
            rows={tableData}
            openDetails={handleOpenDetail}
            columns={headCells}
          />
        )}

        <DetailsModal
          data={selectedData}
          open={openDetails}
          handleMarkAsRead={handleMarkAsRead}
          handleClose={() => setOpenDetails(false)}
        />

        <DeleteCallLogModal
          open={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          handleConfirmDelete={handleConfirmDelete}
        />
      </Box>
    </>
  );
}
