import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { formatPounds } from "../../../misc/utils";
import { green, red, blue } from "@mui/material/colors";
export default function KPIBar({ data, data2 }) {
  // const KPIs = [
  //   {
  //     id: 1,
  //     title: "Total Revenue",
  //     value: 2568,
  //   },
  //   {
  //     id: 2,
  //     title: "Total Collections",
  //     value: 26,
  //   },
  //   {
  //     id: 3,
  //     title: "Total Items",
  //     value: 257,
  //   },
  // ];

  // const InvoiceKPIs = [
  //   {
  //     id: 1,
  //     title: "Invoice Generated",
  //     value: 50,
  //     curreny: true,
  //   },
  //   {
  //     id: 2,
  //     title: "Invoice Paid",
  //     value: 10,
  //     curreny: true,
  //   },
  //   {
  //     id: 3,
  //     title: "Invoice Overdue",
  //     value: 24,
  //   },
  // ];

  return (
    <Box sx={{ px: 3, py: 4 }}>
      <Grid container spacing={4}>
        {data.map((kpi) => (
          <Grid item xs={12} sm={6} md={4} key={kpi.id}>
            <KPICard kpi={kpi} />
          </Grid>
        ))}
        {data2.map((kpi) => (
          <Grid item xs={12} sm={6} md={4} key={kpi.id}>
            <KPICard kpi={kpi} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

const KPICard = ({ kpi }) => (
  <Card sx={{ minWidth: 275, borderRadius: 2, boxShadow: 3 }}>
    <CardContent>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Avatar sx={{ bgcolor: kpi.color || blue[500], width: 48, height: 48 }}>
          {kpi.icon}
        </Avatar>
        <Box>
          <Typography
            variant="h6"
            sx={{ fontWeight: 500, color: "text.secondary" }}
          >
            {kpi.title}
          </Typography>
          <Typography
            variant="h4"
            sx={{ fontWeight: 700, color: "text.primary" }}
          >
            {kpi.currency ? formatPounds(kpi.value) : kpi.value}
          </Typography>
        </Box>
      </Stack>
    </CardContent>
  </Card>
);
