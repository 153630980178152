import { Box, Paper } from "@mui/material";
import React, { useEffect } from "react";
import UsersTable from "./Users/UsersTable";
import { TITLE_PREFIX } from "../../../misc/constant";
import ItemsManagement from "./Items/ItemsManagement";

export default function Settings() {
  useEffect(() => {
    document.title = TITLE_PREFIX + "Settings";
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "#fff",
        padding: 5,
        rowGap: 2,
      }}
    >
      {/* USERS */}
      <Box
        sx={{
          p: 2,
        }}
      >
        <UsersTable />
      </Box>

      {/* Items */}
      <Box
        sx={{
          p: 2,
        }}
      >
        <ItemsManagement />
      </Box>

      {/* IDENTITY */}
      {/* <Identity /> */}
    </Box>
  );
}
